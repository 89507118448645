import { useEffect, useState } from "react";
import { transactionServices } from "services";

const CreditsLogic = () => {

	const [refresh, setRefresh] = useState(false);
	const [items, setItems] = useState([]);

	useEffect(() => {
		(async () => {
			const responseData = await transactionServices.getCredits();
			console.log(responseData);
			if (responseData) {
				setItems(responseData)
			}
		})();
	}, [refresh]);
	
	const refreshData = () => setRefresh(!refresh);

	return {
		items,
		refreshData
	}
}

export default CreditsLogic