import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
} from "@material-ui/core";
// core components
import styles from "assets/jss/material-dashboard-react/components/tableStyle.js";

const useStyles = makeStyles(styles);

export default function CustomTable({ tableHead, tableData, tableHeaderColor }) {
  const classes = useStyles();
  return (
    <div className={classes.tableResponsive}>
      <Table className={classes.table}>
        {tableHead !== undefined ? (
          <TableHead className={classes[tableHeaderColor + "TableHeader"]}>
            <TableRow className={classes.tableHeadRow}>
              {tableHead?.map((prop, key) => (
                <TableCell
                  className={`${classes.tableCell} ${classes.tableHeadCell}`}
                  key={key}
                  style={prop?.props?.large && {
                    display: "flex",
                    justifyContent: "center"
                  }}
                >
                  {prop}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
        ) : null}
        <TableBody>
          {tableData?.map((prop, key) => (
            <TableRow key={key} className={classes.tableBodyRow}>
              {prop.map((prop, key) => (
                <TableCell className={classes.tableCell} key={key} align={tableHead[key].length === 0 ? "right" : 'left'}>
                  {prop}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}

CustomTable.defaultProps = {
  tableHeaderColor: "gray",
};

CustomTable.propTypes = {
  tableHeaderColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray",
  ]),
  tableType: PropTypes.oneOf(["user", "booking", "news", "categories", "event", "links", "files", "alerts", "oubabox", "transactions"]),
  tableHead: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object])),
  tableData: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.any]))
};
